import { Controller } from '@hotwired/stimulus';

/**
 * Usage:
 * - Include `confirmation_modal` partial on a page
 * - Add `data-action="confirmation-modal#showModal"` to a button element
 * - Update the target URL via params; `data-confirmation-modal-url-param="/foo_url"`
 */
export default class extends Controller {
  static targets = ['message', 'form'];
  declare messageTarget?: HTMLElement;
  declare formTarget: HTMLFormElement;

  declare hasMessageTarget: boolean;

  declare modal: HTMLElement;

  declare confirmHandler: (success: boolean) => void;
  declare rejectHandler: (success: boolean) => void;

  connect() {
    this.modal = document.getElementById('confirmation-modal') as HTMLElement;
  }

  showModal({ params: { message, url } }: { params: { message?: string; url?: string } }) {
    if (this.hasMessageTarget && this.messageTarget && message) {
      this.messageTarget.innerHTML = message;
    }

    if (url) this.formTarget.action = url;

    this.modal.classList.remove('hidden');
  }

  close() {
    this.modal.classList.add('hidden');
  }
}
