import { Controller } from '@hotwired/stimulus';

/**
 * Provides tools for filling a directory name input with a value based on another input's value.
 */
export default class extends Controller<HTMLElement> {
  static targets = ['source', 'target'];

  declare readonly sourceTarget: HTMLInputElement;
  declare readonly targetTarget: HTMLInputElement;

  generateIfBlank() {
    if (this.targetTarget.value) return;
    this.regenerate();
  }

  regenerate() {
    this.targetTarget.value = this.sourceTarget.value
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/[^a-z0-9_-]/g, '');
  }
}
